<template>
  <div class="verify-main-content">
    <div class="verify-legend">
      {{ $locale["verify_identity_title"] }}
    </div>
    <div class="verify-features">
      <div class="verify-features-content">
        <CardCheck :card="{ label: $locale['verify_identity_advatage_1'], icon: 'globe', desc: $locale['verify_identity_advatage_1_desc'] }" />
        <CardCheck :card="{ label: $locale.words['your_content_is_safe'], icon: 'mf_lock', desc: $locale.words['your_content_is_safe_desc'] }" />
        <router-link to="/user/verify?apply=step1" pointer dactive :style="$style(applyStyle)">
          <span>{{ $locale["verify_identity_go"] }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    CardCheck: () => import("../widgets/CardCheck.vue"),
  },
  data: function() {
    return {
      applyStyle: {
        display: "inline-block",
        width: "100%",
        padding: "1rem 2rem",
        margin: "var(--dpading) 0",
        color: "#fff",
        "background-image": "var(--sec-gradient)",
        "border-radius": "calc(var(--mpadding) / 2)",
        "text-align": "center",
        "user-select": "none",
      },
    };
  },
};
</script>
